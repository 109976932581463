export const useLayoutStore = defineStore("layoutStore", {
	state: () => ({
		layoutName: "mobile",
		isLoading: false,
		openSidebar: false,
	}),

	actions: {
		setLayout(layout: string) {
			this.layoutName = layout;
		},
		setSidebar(value: boolean) {
			this.openSidebar = value;
		},
		setWrapperLayout() {
			if (this.openSidebar === true) {
				let wrapper = document.getElementsByClassName("wrapper")[0];
				let teb_depthmenu = document.getElementsByClassName("teb_depthmenu")[0];
				wrapper.classList.add("sideon");
				teb_depthmenu.classList.add("sideon");
			}
		},
	},
});
